import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://votecats.tk/"
      }}>{`Vote Cats`}</a>{` is a simple website containing pictures of cats (automatically scraped from `}<a parentName="p" {...{
        "href": "https://thecatapi.com"
      }}>{`TheCatApi.com`}</a>{`) in an infinitely scrolling masonry layout not unlike Pinterest. Users can favorite images for later viewing and create comments on images (which they can delete later if they would like), while admins can delete comments for moderation as well as view deleted comments. Users are prompted to "Sign in with Google" whenever they attempt an action that requires an account, simplifying the process of onboarding users. `}<a parentName="p" {...{
        "href": "https://material.io/"
      }}>{`Material Design`}</a>{` is used for a modern, intuitive feel.`}<br parentName="p"></br>{`
`}{`Under the hood, `}<a parentName="p" {...{
        "href": "https://firebase.google.com/products/functions"
      }}>{`Firebase Functions`}</a>{` backed by `}<a parentName="p" {...{
        "href": "https://firebase.google.com/products/firestore/"
      }}>{`Firebase Firestore`}</a>{` allows the site to run securely and reliably for free with easy scalability.`}<br parentName="p"></br>{`
`}{`The source code is available `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/vote-cats-server"
      }}>{`on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      